// Helper methods
const handlePreventDefault = (ev) => {
  if (ev.keyCode === 13) {
    ev.preventDefault();
  }
};

// Photography Methods
//#region One Photographer
// handle hours amount
export const handleOnePhotographerData = (control, ev, state) => {
  const {
    onePhotographerSelected,
    setOnePhotographerSelected,
    setOnePhotographerControl,
    onePhotographerHour,
    setOnePhotographerHour,
  } = state;

  handlePreventDefault(ev);
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  // TODO: modified accessability
  // if (
  //   ev.type === 'click' ||
  //   ev.type === 'touchend' ||
  //   ((ev.key === 'Enter' || ev.code === 'Enter' || ev.keyCode === 13) && !keyPressed)
  // ) {
  // ev.preventDefault();
  // ev.stopPropagation();

  if (onePhotographerSelected === false) {
    setOnePhotographerSelected(true);
  }

  if (control === 'decrease') {
    setOnePhotographerControl('decrease');
    if (onePhotographerHour > 6) {
      setOnePhotographerHour(onePhotographerHour - 1);
    }
  }

  if (control === 'increase') {
    setOnePhotographerControl('increase');
    setOnePhotographerHour(onePhotographerHour + 1);
  }
  // }
  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#region Second Photographer
// TODO: accessiblity state
// handle selected state
export const handleSecondPhotographerSelected = (ev, state) => {
  const { secondPhotographerSelected, setSecondPhotographerSelected } = state;

  // TODO: accessability
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSecondPhotographerSelected(!secondPhotographerSelected);
  //   handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessiblity state
// handle hours amount
export const handleSecondPhotographerData = (control, ev, state) => {
  const {
    secondPhotographerSelected,
    setSecondPhotographerSelected,
    setSecondPhotographerControl,
    secondPhotographerHour,
    setSecondPhotographerHour,
  } = state;

  handlePreventDefault(ev);
  // TODO: accessability
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (secondPhotographerSelected === false) {
    setSecondPhotographerSelected(true);
  }

  if (control === 'decrease') {
    setSecondPhotographerControl('decrease');
    if (secondPhotographerHour > 4) {
      setSecondPhotographerHour(secondPhotographerHour - 1);
    }
  }

  if (control === 'increase') {
    setSecondPhotographerControl('increase');
    setSecondPhotographerHour(secondPhotographerHour + 1);
  }
  // }

  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#region Engagement Photoshoot
// TODO: accessibility state
export const handleEngagementPhotoshoot = (option, ev, state) => {
  const {
    onePhotographerSelected,
    engagementOneHourPrice,
    engagementOneHourPriceWithOnePhotographer,
    engagementOneHourSelected,
    setEngagementOneHourSelected,
    engagementTwoHoursPrice,
    engagementTwoHoursPriceWithOnePhotographer,
    engagementTwoHoursSelected,
    setEngagementTwoHoursSelected,
    setEngagementPhotoShootPackageInfo,
    setEngagementPhotoShootPrice,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (!onePhotographerSelected) {
    if (option === 1) {
      setEngagementOneHourSelected(!engagementOneHourSelected);
      setEngagementTwoHoursSelected(false);
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
      setEngagementPhotoShootPrice(engagementOneHourPrice);
    }
    if (option === 2) {
      setEngagementOneHourSelected(false);
      setEngagementTwoHoursSelected(!engagementTwoHoursSelected);
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
      setEngagementPhotoShootPrice(engagementTwoHoursPrice);
    }
  }
  if (onePhotographerSelected) {
    if (option === 1) {
      setEngagementOneHourSelected(!engagementOneHourSelected);
      setEngagementTwoHoursSelected(false);
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
      setEngagementPhotoShootPrice(engagementOneHourPriceWithOnePhotographer);
    }
    if (option === 2) {
      setEngagementOneHourSelected(false);
      setEngagementTwoHoursSelected(!engagementTwoHoursSelected);
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
      setEngagementPhotoShootPrice(engagementTwoHoursPriceWithOnePhotographer);
    }
  }
  //   handleKeyPressed(ev.keyCode);
  // }
};

// TODO: accessibility state
export const handleEngagementPhotoshootSelected = (state) => {
  const {
    engagementPhotoShootSelected,
    setEngagementPhotoShootSelected,
    setEngagementOneHourSelected,
    setEngagementTwoHoursSelected,
  } = state;

  setEngagementPhotoShootSelected(!engagementPhotoShootSelected);
  setEngagementOneHourSelected(false);
  setEngagementTwoHoursSelected(false);
};
//#endregion
//#region Wedding Album
// TODO: accessiblity state
export const handleWeddingAlbumSelected = (album, state) => {
  const {
    weddingAlbum10Selected,
    setWeddingAlbum10Selected,
    setWeddingAlbum10ProfessionalDesignSelected,
    weddingAlbum12Selected,
    setWeddingAlbum12Selected,
    setWeddingAlbum12ProfessionalDesignSelected,
  } = state;

  if (album === '10x10') {
    setWeddingAlbum10Selected(!weddingAlbum10Selected);
    setWeddingAlbum10ProfessionalDesignSelected(false);
  } else if (album === '12x12') {
    setWeddingAlbum12Selected(!weddingAlbum12Selected);
    setWeddingAlbum12ProfessionalDesignSelected(false);
  }
};
// TODO: accessiblity state
export const handleWeddingAlbumProfessionalDesignSelected = (album, state) => {
  const {
    weddingAlbum10Selected,
    weddingAlbum10ProfessionalDesignSelected,
    setWeddingAlbum10ProfessionalDesignSelected,
    weddingAlbum12Selected,
    weddingAlbum12ProfessionalDesignSelected,
    setWeddingAlbum12ProfessionalDesignSelected,
  } = state;

  if (album === '10x10') {
    if (weddingAlbum10Selected) {
      setWeddingAlbum10ProfessionalDesignSelected(
        !weddingAlbum10ProfessionalDesignSelected
      );
    }
  }
  if (album === '12x12') {
    if (weddingAlbum12Selected) {
      setWeddingAlbum12ProfessionalDesignSelected(
        !weddingAlbum12ProfessionalDesignSelected
      );
    }
  }
};
// TODO: accessiblity state
export const handleWeddingAlbumAmount = (album, control, state) => {
  const {
    setUriChange,
    weddingAlbum10Selected,
    setWeddingAlbum10Selected,
    setWeddingAlbum10AmountFree,
    setWeddingAlbum10Control,
    weddingAlbum10Amount,
    setWeddingAlbum10Amount,
    setWeddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12Selected,
    setWeddingAlbum12Selected,
    setWeddingAlbum12AmountFree,
    setWeddingAlbum12Control,
    weddingAlbum12Amount,
    setWeddingAlbum12Amount,
    setWeddingAlbum12ProfessionalDesignAmount,
  } = state;
  console.log('TRIIIIIIIIIIIIIIGEEREEED 1');
  // set uri change to false if page refresh has happened
  setUriChange(false);
  if (album === '10x10') {
    setWeddingAlbum10AmountFree(0);

    if (weddingAlbum10Selected === false) {
      setWeddingAlbum10Selected(true);
    }
    if (control === 'increase') {
      setWeddingAlbum10Control(control);
      setWeddingAlbum10Amount(weddingAlbum10Amount + 1);
      setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount + 1);
      setWeddingAlbum10AmountFree(0);
    }

    if (control === 'decrease') {
      setWeddingAlbum10Control(control);
      if (weddingAlbum10Amount > 1) {
        setWeddingAlbum10Amount(weddingAlbum10Amount - 1);
      }
    }
  }

  if (album === '12x12') {
    setWeddingAlbum12AmountFree(0);

    if (weddingAlbum12Selected === false) {
      setWeddingAlbum12Selected(true);
    }
    if (control === 'increase') {
      setWeddingAlbum12Control(control);
      setWeddingAlbum12Amount(weddingAlbum12Amount + 1);
      setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount + 1);
      setWeddingAlbum12AmountFree(0);
    }

    if (control === 'decrease') {
      setWeddingAlbum12Control(control);
      if (weddingAlbum12Amount > 1) {
        setWeddingAlbum12Amount(weddingAlbum12Amount - 1);
      }
    }
  }
};
// TODO: accessibility state
export const handleWeddingAlbumProfessionalDesignAmount = (
  album,
  control,
  state
) => {
  const {
    setUriChange,
    weddingAlbum10Amount,
    weddingAlbum10AmountFree,
    setWeddingAlbum10AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    setWeddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12Amount,
    weddingAlbum12AmountFree,
    setWeddingAlbum12AmountFree,
    weddingAlbum12ProfessionalDesignAmount,
    setWeddingAlbum12ProfessionalDesignAmount,
  } = state;
  // set uri change to false if page refresh has happened
  setUriChange(false);

  if (album === '10x10') {
    if (control === 'increase') {
      if (weddingAlbum10Amount > weddingAlbum10ProfessionalDesignAmount) {
        setWeddingAlbum10ProfessionalDesignAmount(
          weddingAlbum10ProfessionalDesignAmount + 1
        );
      }
      if (
        weddingAlbum10AmountFree > 0 &&
        weddingAlbum10AmountFree !== weddingAlbum10Amount
      ) {
        setWeddingAlbum10AmountFree(weddingAlbum10AmountFree - 1);
      }
    }

    if (control === 'decrease') {
      if (weddingAlbum10Amount > weddingAlbum10AmountFree) {
        setWeddingAlbum10AmountFree(weddingAlbum10AmountFree + 1);
      }
      setWeddingAlbum10ProfessionalDesignAmount(
        weddingAlbum10ProfessionalDesignAmount - 1
      );
    }
  }

  if (album === '12x12') {
    if (control === 'increase') {
      if (weddingAlbum12Amount > weddingAlbum12ProfessionalDesignAmount) {
        setWeddingAlbum12ProfessionalDesignAmount(
          weddingAlbum12ProfessionalDesignAmount + 1
        );
      }
      if (
        weddingAlbum12AmountFree > 0 &&
        weddingAlbum12AmountFree !== weddingAlbum12Amount
      ) {
        setWeddingAlbum12AmountFree(weddingAlbum12AmountFree - 1);
      }
    }

    if (control === 'decrease') {
      if (weddingAlbum12Amount > weddingAlbum12AmountFree) {
        setWeddingAlbum12AmountFree(weddingAlbum12AmountFree + 1);
      }

      setWeddingAlbum12ProfessionalDesignAmount(
        weddingAlbum12ProfessionalDesignAmount - 1
      );
    }
  }
};
//#endregion
//#region Photo Retouching
// TODO: accessibility state
export const handlePhotoRetouching = (option, ev, state) => {
  const {
    retouchedPhotos10Price,
    photoRetouching10Selected,
    setPhotoRetouching10Selected,
    retouchedPhotos20Price,
    photoRetouching20Selected,
    setPhotoRetouching20Selected,
    retouchedPhotos30Price,
    photoRetouching30Selected,
    setPhotoRetouching30Selected,
    setPhotoRetouchingPackageInfo,
    setPhotoRetouchingPackagePrice,
  } = state;
  handlePreventDefault(ev);
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (option === 10) {
    setPhotoRetouching10Selected(!photoRetouching10Selected);
    setPhotoRetouching20Selected(false);
    setPhotoRetouching30Selected(false);
    setPhotoRetouchingPackageInfo('10 retouched photos');
    setPhotoRetouchingPackagePrice(retouchedPhotos10Price);
  }
  if (option === 20) {
    setPhotoRetouching10Selected(false);
    setPhotoRetouching20Selected(!photoRetouching20Selected);
    setPhotoRetouching30Selected(false);
    setPhotoRetouchingPackageInfo('20 retouched photos');
    setPhotoRetouchingPackagePrice(retouchedPhotos20Price);
  }
  if (option === 30) {
    setPhotoRetouching10Selected(false);
    setPhotoRetouching20Selected(false);
    setPhotoRetouching30Selected(!photoRetouching30Selected);
    setPhotoRetouchingPackageInfo('30 retouched photos');
    setPhotoRetouchingPackagePrice(retouchedPhotos30Price);
  }
  // handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessibility state
export const handlePhotoRetouchingSelected = (state) => {
  const {
    photoRetouchingSelected,
    setPhotoRetouchingSelected,
    setPhotoRetouching10Selected,
    setPhotoRetouching20Selected,
    setPhotoRetouching30Selected,
  } = state;
  setPhotoRetouchingSelected(!photoRetouchingSelected);
  setPhotoRetouching10Selected(false);
  setPhotoRetouching20Selected(false);
  setPhotoRetouching30Selected(false);
};
//#endregion
//#region Expedited Photo Processing
// TODO: accessibility state
export const handleExpeditedPhotoProcessing = (option, ev, state) => {
  const {
    expeditedPhotoProcessingSelected,
    setExpeditedPhotoProcessingSelected,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (option === 1) {
    setExpeditedPhotoProcessingSelected(!expeditedPhotoProcessingSelected);
  }
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
//#region Premium Package (Photo)
// TODO: accessibility state
export const handlePremiumPhotoPackageSelected = (ev, state) => {
  const { premiumPhotoPackageSelected, setPremiumPhotoPackageSelected } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setPremiumPhotoPackageSelected(!premiumPhotoPackageSelected);
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
// Videography
//#region One Videographer
// TODO: accessibility state
// handle selected state
// export const handleOneVideographerSelected = (ev, state) => {
//   clearStoragePackageState();
//   if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
//     setOneVideographerSelected(!oneVideographerSelected);
//     setCinematographyPrice(cinematographyPriceWithOneVideographer);
//     handleKeyPressed(ev.keyCode);
//   }
// };
// TODO: accessibility state
// handle hours amount
export const handleOneVideographerData = (control, ev, state) => {
  const {
    oneVideographerSelected,
    setOneVideographerSelected,
    setOneVideographerControl,
    oneVideographerHour,
    setOneVideographerHour,
  } = state;

  handlePreventDefault(ev);
  // console.log('HOVD', control, ev);
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (oneVideographerSelected === false) {
    setOneVideographerSelected(true);
  }

  if (control === 'decrease') {
    setOneVideographerControl('decrease');
    if (oneVideographerHour > 4) {
      setOneVideographerHour(oneVideographerHour - 1);
    }
  }
  if (control === 'increase') {
    setOneVideographerControl('increase');
    setOneVideographerHour(oneVideographerHour + 1);
  }
  // }
  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#reion Second Videographer
// TODO: accessibility state
export const handleSecondVideographerSelected = (ev, state) => {
  const { secondVideographerSelected, setSecondVideographerSelected } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSecondVideographerSelected(!secondVideographerSelected);
  // handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessibility state
// handle hours amount
export const handleSecondVideographerData = (control, ev, state) => {
  const {
    secondVideographerSelected,
    setSecondVideographerSelected,
    setSecondVideographerControl,
    secondVideographerHour,
    setSecondVideographerHour,
  } = state;

  handlePreventDefault(ev);
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (secondVideographerSelected === false) {
    setSecondVideographerSelected(true);
  }

  if (control === 'decrease') {
    setSecondVideographerControl('decrease');
    if (secondVideographerHour > 4) {
      setSecondVideographerHour(secondVideographerHour - 1);
    }
  }
  if (control === 'increase') {
    setSecondVideographerControl('increase');
    setSecondVideographerHour(secondVideographerHour + 1);
  }
  // }
  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#region Video Editing Options
// TODO: accessiblity state
export const handleCinematographySelected = (ev, state) => {
  const {
    cinematographySelected,
    setCinematographySelected,
    setTrailerOnlySelected,
    setExtendedTrailerSelected,
    setTraditionalSelected,
  } = state;

  setCinematographySelected(!cinematographySelected);
  setTrailerOnlySelected(false);
  setExtendedTrailerSelected(false);
  setTraditionalSelected(false);
};
// TODO: accessiblity state
export const handleTrailerOnlySelected = (ev, state) => {
  const {
    trailerOnlySelected,
    traditionalSelected,
    setTraditionalSelected,
    setCinematographySelected,
    setTrailerOnlySelected,
    setExtendedTrailerSelected,
  } = state;

  setCinematographySelected(false);
  setTrailerOnlySelected(!trailerOnlySelected);
  setExtendedTrailerSelected(false);
  setTraditionalSelected(false);
  if (traditionalSelected) {
    setCinematographySelected(false);
    if (trailerOnlySelected) {
      setTrailerOnlySelected(false);
    } else {
      setTrailerOnlySelected(true);
    }
    setExtendedTrailerSelected(false);
    setTraditionalSelected(true);
  }
};
// TODO: accessiblity state
export const handleExtendedTrailerSelected = (ev, state) => {
  const {
    extendedTrailerSelected,
    traditionalSelected,
    setTraditionalSelected,
    setCinematographySelected,
    setTrailerOnlySelected,
    setExtendedTrailerSelected,
  } = state;

  setCinematographySelected(false);
  setTrailerOnlySelected(false);
  setExtendedTrailerSelected(!extendedTrailerSelected);
  setTraditionalSelected(false);
  if (traditionalSelected) {
    setCinematographySelected(false);
    setTrailerOnlySelected(false);
    if (extendedTrailerSelected) {
      setExtendedTrailerSelected(false);
    } else {
      setExtendedTrailerSelected(true);
    }
    setTraditionalSelected(true);
  }
};
export const handleTraditionalSelected = (ev, state) => {
  const {
    traditionalSelected,
    setCinematographySelected,
    setTraditionalSelected,
  } = state;

  setTraditionalSelected(!traditionalSelected);
  setCinematographySelected(false);
};
//#endregion
//#region Premium Package (Video)
// TODO: accessiblity state
export const handlePremiumVideoPackageSelected = (ev, state) => {
  const { premiumVideoPackageSelected, setPremiumVideoPackageSelected } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setPremiumVideoPackageSelected(!premiumVideoPackageSelected);
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
//#region Sneak Peek
// TODO: accessibility state
export const handleSocialMediaPackagePhotoSelected = (ev, state) => {
  const {
    socialMediaPackagePhotoSelected,
    setSocialMediaPackagePhotoSelected,
    setSocialMediaPackageVideoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackagePhotoSelected(!socialMediaPackagePhotoSelected);
  setSocialMediaPackageVideoSelected(false);
  setSocialMediaPackagePhotoAndVideoSelected(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessibility state
export const handleSocialMediaPackageVideoSelected = (ev, state) => {
  const {
    socialMediaPackageVideoSelected,
    setSocialMediaPackageVideoSelected,
    setSocialMediaPackagePhotoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackageVideoSelected(!socialMediaPackageVideoSelected);
  setSocialMediaPackagePhotoSelected(false);
  setSocialMediaPackagePhotoAndVideoSelected(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessibility state
export const handleSocialMediaPackagePhotoAndVideoSelected = (ev, state) => {
  const {
    socialMediaPackagePhotoAndVideoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
    setSocialMediaPackagePhotoSelected,
    setSocialMediaPackageVideoSelected,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackagePhotoAndVideoSelected(
    !socialMediaPackagePhotoAndVideoSelected
  );
  setSocialMediaPackagePhotoSelected(false);
  setSocialMediaPackageVideoSelected(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
//#region Photo Booth
// TODO: accessiblity state
export const handlePhotoBoothSelected = (option, state) => {
  const {
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    setPhotoBoothBasicSelected,
    setPhotoBoothAllInclusiveSelected,
  } = state;

  if (option === 'basic') {
    setPhotoBoothBasicSelected(!photoBoothBasicSelected);
    setPhotoBoothAllInclusiveSelected(false);
  }
  if (option === 'all-inclusive') {
    setPhotoBoothBasicSelected(false);
    setPhotoBoothAllInclusiveSelected(!photoBoothAllInclusiveSelected);
  }
};
//#endregion
