import { useState } from 'react';
import { getUrlParams } from '../utils/urlParamsUtils';

export const usePackageConfiguratorWeddingsState = (graphqlData) => {
  // Discounts
  // TODO: write proper code, its hardcoded for now
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  // Photography
  //#region One Photographer
  const [onePhotographerSelected, setOnePhotographerSelected] = useState(() => {
    const p1a = getUrlParams('p1', location.search, true);
    if (p1a === 'true' || p1a === true) return true;
    return false;
  });
  const [onePhotographerControl, setOnePhotographerControl] = useState('');
  const [onePhotographerHour, setOnePhotographerHour] = useState(() => {
    const p1t = Number(getUrlParams('p1t', location.search, true));
    if (p1t < 6) return 8;
    if (p1t) {
      return Number(getUrlParams('p1t', location.search, true));
    } else {
      return 8;
    }
  });
  const [onePhotographerCCImages, setOnePhotographerCCImages] = useState(
    Number(graphqlData.onePhotographer.averageColorCorrectedImagesFor8)
  );
  const [onePhotographerPriceDefault] = useState(
    Number(graphqlData.onePhotographer.priceFor8)
  );
  const [onePhotographerPrice, setOnePhotographerPrice] = useState(
    Number(graphqlData.onePhotographer.priceFor8)
  );
  const [onePhotographerPriceForBelow8] = useState(
    Number(graphqlData.onePhotographer.pricePerHourBelow8)
  );
  const [onePhotographerPriceForAbove8] = useState(
    Number(graphqlData.onePhotographer.pricePerHour8AndAbove)
  );
  const [onePhotographerCCImages8] = useState(
    Number(graphqlData.onePhotographer.averageColorCorrectedImagesFor8)
  );
  const [onePhotographerCCImagesAbove8] = useState(
    Number(graphqlData.onePhotographer.colorCorrectedImagesAmount8AndAbove)
  );
  const [onePhotographerCCImagesBelow8] = useState(
    Number(graphqlData.onePhotographer.colorCorrectedImagesAmountBelow8)
  );
  //#endregion
  //#region Second Photographer
  const [secondPhotographerSelected, setSecondPhotographerSelected] = useState(
    () => {
      const p2a = getUrlParams('p2', location.search, true);
      if (p2a === 'true' || p2a === true) return true;
      return false;
    }
  );
  const [secondPhotographerControl, setSecondPhotographerControl] =
    useState('');
  const [secondPhotographerHour, setSecondPhotographerHour] = useState(() => {
    const p2t = Number(getUrlParams('p2t', location.search, true));
    if (p2t < 4) return 8;
    if (p2t) {
      return Number(getUrlParams('p2t', location.search, true));
    } else {
      return 8;
    }
  });
  const [secondPhotographerPriceDefault] = useState(
    Number(graphqlData.secondPhotographer.priceFor8)
  );
  const [secondPhotographerPrice, setSecondPhotographerPrice] = useState(
    Number(graphqlData.secondPhotographer.priceFor8)
  );
  const [secondPhotographerCCImages, setSecondPhotographerCCImages] = useState(
    Number(graphqlData.secondPhotographer.averageColorCorrectedImagesFor8)
  );
  const [secondPhotographerPriceForAbove8] = useState(
    Number(graphqlData.secondPhotographer.pricePerHour8AndAbove)
  );
  const [secondPhotographerPriceForBelow8] = useState(
    Number(graphqlData.secondPhotographer.pricePerHourBelow8)
  );
  const [secondPhotographerCCImages8] = useState(
    Number(graphqlData.secondPhotographer.averageColorCorrectedImagesFor8)
  );
  const [secondPhotographerCCImagesAbove8] = useState(
    Number(graphqlData.secondPhotographer.colorCorrectedImagesAmount8AndAbove)
  );
  const [secondPhotographerCCImagesBelow8] = useState(
    Number(graphqlData.secondPhotographer.colorCorrectedImagesAmountBelow8)
  );
  //#endregion
  //#region Engagement photoshoot
  const [engagementOneHourPrice] = useState(
    Number(graphqlData.engagementOneHour.price)
  );
  const [engagementOneHourPriceWithOnePhotographer] = useState(
    Number(graphqlData.engagementOneHourWithOnePhotographer.price)
  );
  const [engagementTwoHoursPrice] = useState(
    Number(graphqlData.engagementTwoHours.price)
  );
  const [engagementTwoHoursPriceWithOnePhotographer] = useState(
    Number(graphqlData.engagementTwoHoursWithOnePhotographer.price)
  );
  const [engagementPhotoShootSelected, setEngagementPhotoShootSelected] =
    useState(false);
  const [engagementPhotoShootPackageInfo, setEngagementPhotoShootPackageInfo] =
    useState('');
  const [engagementPhotoShootPrice, setEngagementPhotoShootPrice] = useState(0);
  const [engagementOneHourSelected, setEngagementOneHourSelected] = useState(
    () => {
      const e1a = getUrlParams('e1', location.search, true);
      if (e1a === 'true' || e1a === true) return true;
      return false;
    }
  );
  const [engagementTwoHoursSelected, setEngagementTwoHoursSelected] = useState(
    () => {
      const e2a = getUrlParams('e2', location.search, true);
      if (e2a === 'true' || e2a === true) return true;
      return false;
    }
  );
  //#endregion
  //#region Wedding Albums
  const [uriChange, setUriChange] = useState(false);
  //#region 10x10
  const [weddingAlbum10Price, setWeddingAlbum10Price] = useState(0);
  const [weddingAlbum10PriceTotal, setWeddingAlbum10PriceTotal] = useState(0);
  const [weddingAlbum10Selected, setWeddingAlbum10Selected] = useState(() => {
    const w10 = getUrlParams('w10', location.search, true);
    if (w10 === 'true' || w10 === true) return true;
    return false;
  });
  const [weddingAlbum10Amount, setWeddingAlbum10Amount] = useState(() => {
    const w10a = Number(getUrlParams('w10a', location.search, true));
    if (w10a < 1) return 1;
    if (w10a) {
      return Number(getUrlParams('w10a', location.search, true));
    } else {
      return 1;
    }
  });
  const [weddingAlbum10AmountFree, setWeddingAlbum10AmountFree] = useState(
    () => {
      const w10af = Number(getUrlParams('w10af', location.search, true));
      if (w10af < 1) return 0;
      if (w10af) {
        return Number(getUrlParams('w10af', location.search, true));
      } else {
        return 0;
      }
    }
  );
  const [
    weddingAlbum10ProfessionalDesignAmount,
    setWeddingAlbum10ProfessionalDesignAmount,
  ] = useState(() => {
    const w10pda = Number(getUrlParams('w10pda', location.search, true));
    if (w10pda) {
      if (w10pda < 1) return 1;
      return Number(getUrlParams('w10pda', location.search, true));
    } else {
      return 1;
    }
  });
  const [
    weddingAlbum10ProfessionalDesignSelected,
    setWeddingAlbum10ProfessionalDesignSelected,
  ] = useState(() => {
    const w10pds = getUrlParams('w10pds', location.search, true);
    if (w10pds === 'true' || w10pds === true) return true;
    return false;
  });
  const [weddingAlbum10Control, setWeddingAlbum10Control] = useState('');
  const [weddingAlbum10Info, setWeddingAlbum10Info] = useState('Album');
  const [weddingAlbum10AmountFreeInfo, setWeddingAlbum10AmountFreeInfo] =
    useState('Album');
  const [
    weddingAlbum10ProfessionalDesignInfo,
    setWeddingAlbum10ProfessionalDesignInfo,
  ] = useState('Design');
  const [weddingAlbum10PriceDefault] = useState(
    Number(graphqlData.weddingAlbum10.price)
  );
  const [weddingAlbum10ShippingFee] = useState(
    Number(graphqlData.weddingAlbum10.shippingFee)
  );
  const [weddingAlbum10ShippingFeeTotal, setWeddingAlbum10ShippingFeeTotal] =
    useState(Number(graphqlData.weddingAlbum10.shippingFee));
  const [weddingAlbum10ProfessionalDesignPrice] = useState(
    Number(graphqlData.weddingAlbum10.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum10ProfessionalDesignPriceTotal,
    setWeddingAlbum10ProfessionalDesignPriceTotal,
  ] = useState(Number(graphqlData.weddingAlbum10.professionalAlbumDesignPrice));
  //#endregion
  //#region 12x12
  const [weddingAlbum12Price, setWeddingAlbum12Price] = useState(0);
  const [weddingAlbum12PriceTotal, setWeddingAlbum12PriceTotal] = useState(0);
  const [weddingAlbum12Selected, setWeddingAlbum12Selected] = useState(() => {
    const w12 = getUrlParams('w12', location.search, true);
    if (w12 === 'true' || w12 === true) return true;
    return false;
  });
  const [weddingAlbum12Amount, setWeddingAlbum12Amount] = useState(() => {
    const w12a = Number(getUrlParams('w12a', location.search, true));
    if (w12a < 1) return 1;
    if (w12a) {
      return Number(getUrlParams('w12a', location.search, true));
    } else {
      return 1;
    }
  });
  const [weddingAlbum12AmountFree, setWeddingAlbum12AmountFree] = useState(
    () => {
      const w12af = Number(getUrlParams('w12af', location.search, true));
      if (w12af < 1) return 0;
      if (w12af) {
        return Number(getUrlParams('w12af', location.search, true));
      } else {
        return 0;
      }
    }
  );
  const [
    weddingAlbum12ProfessionalDesignAmount,
    setWeddingAlbum12ProfessionalDesignAmount,
  ] = useState(() => {
    const w12pda = Number(getUrlParams('w12pda', location.search, true));
    if (w12pda) {
      if (w12pda < 1) return 1;
      return Number(getUrlParams('w12pda', location.search, true));
    } else {
      return 1;
    }
  });
  const [
    weddingAlbum12ProfessionalDesignSelected,
    setWeddingAlbum12ProfessionalDesignSelected,
  ] = useState(() => {
    const w12pds = getUrlParams('w12pds', location.search, true);
    if (w12pds === 'true' || w12pds === true) return true;
    return false;
  });
  const [weddingAlbum12Control, setWeddingAlbum12Control] = useState('');
  const [weddingAlbum12Info, setWeddingAlbum12Info] = useState('Album');
  const [weddingAlbum12AmountFreeInfo, setWeddingAlbum12AmountFreeInfo] =
    useState('Album');
  const [
    weddingAlbum12ProfessionalDesignInfo,
    setWeddingAlbum12ProfessionalDesignInfo,
  ] = useState('Design');
  const [weddingAlbum12PriceDefault] = useState(
    Number(graphqlData.weddingAlbum12.price)
  );
  const [weddingAlbum12ShippingFee] = useState(
    Number(graphqlData.weddingAlbum12.shippingFee)
  );
  const [weddingAlbum12ShippingFeeTotal, setWeddingAlbum12ShippingFeeTotal] =
    useState(Number(graphqlData.weddingAlbum12.shippingFee));
  const [weddingAlbum12ProfessionalDesignPrice] = useState(
    Number(graphqlData.weddingAlbum12.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum12ProfessionalDesignPriceTotal,
    setWeddingAlbum12ProfessionalDesignPriceTotal,
  ] = useState(Number(graphqlData.weddingAlbum10.professionalAlbumDesignPrice));
  //#endregion
  //#endregion
  //#region Photo retouching
  const [retouchedPhotos10Price] = useState(graphqlData.retouchedPhotos10);
  const [photoRetouching10Selected, setPhotoRetouching10Selected] = useState(
    () => {
      const pr1a = getUrlParams('pr1', location.search, true);
      if (pr1a === 'true' || pr1a === true) return true;
      return false;
    }
  );
  const [retouchedPhotos20Price] = useState(graphqlData.retouchedPhotos20);
  const [photoRetouching20Selected, setPhotoRetouching20Selected] = useState(
    () => {
      const pr2a = getUrlParams('pr2', location.search, true);
      if (pr2a === 'true' || pr2a === true) return true;
      return false;
    }
  );
  const [retouchedPhotos30Price] = useState(graphqlData.retouchedPhotos30);
  const [photoRetouching30Selected, setPhotoRetouching30Selected] = useState(
    () => {
      const pr3a = getUrlParams('pr3', location.search, true);
      if (pr3a === 'true' || pr3a === true) return true;
      return false;
    }
  );
  const [photoRetouchingSelected, setPhotoRetouchingSelected] = useState(false);
  const [photoRetouchingPackageInfo, setPhotoRetouchingPackageInfo] =
    useState('');
  const [photoRetouchingPackagePrice, setPhotoRetouchingPackagePrice] =
    useState(0);
  //#endregion
  //#region Expedited photo processing
  const [
    expeditedPhotoProcessingSelected,
    setExpeditedPhotoProcessingSelected,
  ] = useState(() => {
    const eppa = getUrlParams('epp', location.search, true);
    if (eppa === 'true' || eppa === true) return true;
    return false;
  });
  const [expeditedPhotoProcessingPrice] = useState(
    Number(graphqlData.expeditedPhotoProcessing.price)
  );
  //#endregion
  //#region Premium package (Photo)
  const [premiumPhotoPackageSelected, setPremiumPhotoPackageSelected] =
    useState(() => {
      const pppa = getUrlParams('ppp', location.search, true);
      if (pppa === 'true' || pppa === true) return true;
      return false;
    });
  const [premiumPhotoPackagePrice, setPremiumPhotoPackagePrice] = useState(
    Number(graphqlData.premiumPhotoPackage.price)
  );
  const [premiumPhotoPackagePriceDefault] = useState(
    Number(graphqlData.premiumPhotoPackage.price)
  );
  // TODO: if/when needed, combined with premiumPhotoPackagePriceDiscount
  // const [premiumPhotoPackagePriceDiscount] = useState(
  //   Number(graphqlData.premiumPhotoPackage.discount)
  // );
  const [premiumPhotoPackageDescription] = useState(
    graphqlData.premiumPhotoPackage.description
  );
  const [premiumPhotoPackagePopupTitle, setPremiumPhotoPackagePopupTitle] =
    useState(graphqlData.premiumPhotoPackage.popupTitle);
  // TODO: if/when needed
  // const [premiumPhotoPackagePopupTitleDefault] = useState(
  //   graphqlData.premiumPhotoPackage.popupTitle
  // );
  // const [premiumPhotoPackagePopupTitleDiscount] = useState(
  //   graphqlData.premiumPhotoPackage.popupTitleDiscount
  // );
  const [premiumPhotoPackagePopupBody] = useState(
    graphqlData.premiumPhotoPackage.popupBody
  );
  //#endregion
  // Videography
  //#region One Videographer
  const [oneVideographerSelected, setOneVideographerSelected] = useState(() => {
    const v1a = getUrlParams('v1', location.search, true);
    if (v1a === 'true' || v1a === true) return true;
    return false;
  });
  const [oneVideographerControl, setOneVideographerControl] = useState('');
  const [oneVideographerHour, setOneVideographerHour] = useState(() => {
    const v1t = Number(getUrlParams('v1t', location.search, true));
    if (v1t < 4) return 8;
    if (v1t) {
      return Number(getUrlParams('v1t', location.search, true));
    } else {
      return 8;
    }
  });
  const [oneVideographerPriceDefault] = useState(
    Number(graphqlData.oneVideographer.priceFor8)
  );
  const [oneVideographerPrice, setOneVideographerPrice] = useState(
    Number(graphqlData.oneVideographer.priceFor8)
  );
  const [oneVideographerPriceForAbove8] = useState(
    Number(graphqlData.oneVideographer.pricePerHour8AndAbove)
  );
  const [oneVideographerPriceForBelow8] = useState(
    Number(graphqlData.oneVideographer.pricePerHourBelow8)
  );
  const [oneVideographerPriceWithOnePhotographer] = useState(
    Number(graphqlData.oneVideographer.priceFor8WithOnePhotographer)
  );
  //#endregion
  //#region Second Videographer
  const [secondVideographerSelected, setSecondVideographerSelected] = useState(
    () => {
      const v2a = getUrlParams('v2', location.search, true);
      if (v2a === 'true' || v2a === true) return true;
      return false;
    }
  );
  const [secondVideographerControl, setSecondVideographerControl] =
    useState('');
  const [secondVideographerHour, setSecondVideographerHour] = useState(() => {
    const v2t = Number(getUrlParams('v2t', location.search, true));
    if (v2t < 4) return 8;
    if (v2t) {
      return Number(getUrlParams('v2t', location.search, true));
    } else {
      return 8;
    }
  });
  const [secondVideographerPriceDefault] = useState(
    Number(graphqlData.secondVideographer.priceFor8)
  );
  const [secondVideographerPrice, setSecondVideographerPrice] = useState(
    Number(graphqlData.secondVideographer.priceFor8)
  );
  const [secondVideographerPriceForAbove8] = useState(
    Number(graphqlData.secondVideographer.pricePerHour8AndAbove)
  );
  const [secondVideographerPriceForBelow8] = useState(
    Number(graphqlData.secondVideographer.pricePerHourBelow8)
  );
  //#endregion
  // Video editing options
  //#region Cinematography
  const [cinematographySelected, setCinematographySelected] = useState(() => {
    const o1a = getUrlParams('o1', location.search, true);
    if (o1a === 'true' || o1a === true) return true;
    return false;
  });
  const [cinematographyPrice, setCinematographyPrice] = useState(0);
  const [cinematographyPriceWithOneVideographer] = useState(
    Number(graphqlData.videoEditingOptions.cinematographyPrice)
  );
  const [cinematographyPriceWithSecondVideographer] = useState(
    graphqlData.videoEditingOptions.cinematographyPriceWithSecondVideographer
  );
  //#endregion
  //#region Trailer
  const [trailerOnlySelected, setTrailerOnlySelected] = useState(() => {
    const o2a = getUrlParams('o2', location.search, true);
    if (o2a === 'true' || o2a === true) return true;
    return false;
  });
  const [trailerOnlyPrice, setTrailerOnlyPrice] = useState(0);
  const [trailerOnlyPriceWithOneVideographer] = useState(
    Number(graphqlData.videoEditingOptions.trailerOnlyPrice)
  );
  const [trailerOnlyPriceWithSecondVideographer] = useState(
    Number(
      graphqlData.videoEditingOptions.trailerOnlyPriceWithSecondVideographer
    )
  );
  //#endregion
  //#region Extended trailer
  const [extendedTrailerSelected, setExtendedTrailerSelected] = useState(() => {
    const o3a = getUrlParams('o3', location.search, true);
    if (o3a === 'true' || o3a === true) return true;
    return false;
  });
  const [extendedTrailerPrice, setExtendedTrailerPrice] = useState(0);
  const [extendedTrailerPriceWithOneVideographer] = useState(
    Number(graphqlData.videoEditingOptions.extendedTrailerPrice)
  );
  const [extendedTrailerPriceWithSecondVideographer] = useState(
    Number(
      graphqlData.videoEditingOptions.extendedTrailerPriceWithSecondVideographer
    )
  );
  //#endregion
  //#region Traditional
  const [traditionalSelected, setTraditionalSelected] = useState(() => {
    const o4a = getUrlParams('o4', location.search, true);
    if (o4a === 'true' || o4a === true) return true;
    return false;
  });
  const [traditionalPrice, setTraditionalPrice] = useState(0);
  const [traditionalPriceWithOneVideographer] = useState(
    Number(graphqlData.videoEditingOptions.traditionalPrice)
  );
  const [traditionalPriceWithSecondVideographer] = useState(
    Number(
      graphqlData.videoEditingOptions.traditionalPriceWithSecondVideographer
    )
  );
  //#endregion
  //#region Premium Package (Video)
  const [premiumVideoPackageSelected, setPremiumVideoPackageSelected] =
    useState(() => {
      const pvp = getUrlParams('pvp', location.search, true);
      if (pvp === 'true' || pvp === true) return true;
      return false;
    });
  const [premiumVideoPackageDescription, setPremiumVideoPackageDescription] =
    useState('');
  const [premiumVideoPackagePrice, setPremiumVideoPackagePrice] = useState(
    Number(graphqlData.premiumVideoPackage.price)
  );
  const [premiumVideoPackagePriceDefault] = useState(
    Number(graphqlData.premiumVideoPackage.price)
  );
  const [premiumVideoPackagePriceDiscount] = useState(
    Number(graphqlData.premiumVideoPackage.discount)
  );
  const [premiumVideoPackageDescriptionDefault] = useState(
    graphqlData.premiumVideoPackage.description
  );
  const [premiumVideoPackagePopupTitle, setPremiumVideoPackagePopupTitle] =
    useState(graphqlData.premiumVideoPackage.popupTitle);
  const [premiumVideoPackagePopupTitleDefault] = useState(
    graphqlData.premiumVideoPackage.popupTitle
  );
  const [premiumVideoPackagePopupTitleDiscount] = useState(
    graphqlData.premiumVideoPackage.popupTitleDiscount
  );
  const [premiumVideoPackagePopupBody] = useState(
    graphqlData.premiumVideoPackage.popupBody
  );
  //#endregion
  // Social Media Package (Sneak Peek)
  //#region Sneak Peek Photo
  const [socialMediaPackagePhotoSelected, setSocialMediaPackagePhotoSelected] =
    useState(() => {
      const sppa = getUrlParams('spp', location.search, true);
      if (sppa === 'true' || sppa === true) return true;
      return false;
    });
  const [socialMediaPackagePhotoPrice] = useState(
    Number(graphqlData.socialMediaPackage.photo.price)
  );
  const [socialMediaPackagePhotoReels] = useState(
    graphqlData.socialMediaPackage.photo
  );
  //#endregion
  //#region Sneak Peek Video
  const [socialMediaPackageVideoSelected, setSocialMediaPackageVideoSelected] =
    useState(() => {
      const spva = getUrlParams('spv', location.search, true);
      if (spva === 'true' || spva === true) return true;
      return false;
    });
  const [socialMediaPackageVideoPrice] = useState(
    Number(graphqlData.socialMediaPackage.video.price)
  );
  const [socialMediaPackageVideoReels] = useState(
    graphqlData.socialMediaPackage.video
  );
  //#endregion
  //#region Sneak Peek Photo & Video
  const [
    socialMediaPackagePhotoAndVideoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
  ] = useState(() => {
    const sppva = getUrlParams('sppv', location.search, true);
    if (sppva === 'true' || sppva === true) return true;
    return false;
  });
  const [socialMediaPackagePhotoAndVideoPrice] = useState(
    Number(graphqlData.socialMediaPackage.photoAndVideo.price)
  );
  const [socialMediaPackagePhotoAndVideoReels] = useState(
    graphqlData.socialMediaPackage.photoAndVideo
  );
  //#endregion
  //#region Photo Booth
  const [photoBoothPackagePrice, setPhotoBoothPackagePrice] = useState(0);
  //#endregion
  //#region Basic Package
  const [photoBoothBasicSelected, setPhotoBoothBasicSelected] = useState(() => {
    const pb1a = getUrlParams('pb1', location.search, true);
    if (pb1a === 'true' || pb1a === true) return true;
    return false;
  });
  const [photoBoothBasicPackagePriceDefault] = useState(
    Number(graphqlData.photoBooth.basicPackagePrice)
  );
  const [photoBoothBasicPackagePrice, setPhotoBoothBasicPackagePrice] =
    useState(Number(graphqlData.photoBooth.basicPackagePrice));
  const [photoBoothBasicPackagePriceWithOnePhotographer] = useState(
    Number(graphqlData.photoBooth.basicPackagePriceWithOnePhotographer)
  );
  //#endregion
  //#region All Inclusive Package
  const [photoBoothAllInclusiveSelected, setPhotoBoothAllInclusiveSelected] =
    useState(() => {
      const pb2a = getUrlParams('pb2', location.search, true);
      if (pb2a === 'true' || pb2a === true) return true;
      return false;
    });
  const [photoBoothAllInclusivePriceDefault] = useState(
    Number(graphqlData.photoBooth.allInclusivePrice)
  );
  const [photoBoothAllInclusivePrice, setPhotoBoothAllInclusivePrice] =
    useState(Number(graphqlData.photoBooth.allInclusivePrice));
  const [photoBoothAllInclusivePriceWithOnePhotographer] = useState(
    Number(graphqlData.photoBooth.allInclusivePriceWithOnePhotographer)
  );
  //#endregion

  return {
    // Discounts
    discountAmount,
    setDiscountAmount,
    discountApplied,
    setDiscountApplied,
    // Photography
    // One Photographer
    onePhotographerSelected,
    setOnePhotographerSelected,
    onePhotographerControl,
    setOnePhotographerControl,
    onePhotographerHour,
    setOnePhotographerHour,
    onePhotographerCCImages,
    setOnePhotographerCCImages,
    onePhotographerPriceDefault,
    onePhotographerPrice,
    setOnePhotographerPrice,
    onePhotographerPriceForBelow8,
    onePhotographerPriceForAbove8,
    onePhotographerCCImages8,
    onePhotographerCCImagesAbove8,
    onePhotographerCCImagesBelow8,
    // Second Photographer
    secondPhotographerSelected,
    setSecondPhotographerSelected,
    secondPhotographerControl,
    setSecondPhotographerControl,
    secondPhotographerHour,
    setSecondPhotographerHour,
    secondPhotographerPriceDefault,
    secondPhotographerPrice,
    setSecondPhotographerPrice,
    secondPhotographerCCImages,
    setSecondPhotographerCCImages,
    secondPhotographerPriceForAbove8,
    secondPhotographerPriceForBelow8,
    secondPhotographerCCImages8,
    secondPhotographerCCImagesAbove8,
    secondPhotographerCCImagesBelow8,
    // Engagement Photoshoot
    engagementPhotoShootSelected,
    setEngagementPhotoShootSelected,
    engagementPhotoShootPackageInfo,
    setEngagementPhotoShootPackageInfo,
    engagementPhotoShootPrice,
    setEngagementPhotoShootPrice,
    engagementOneHourSelected,
    setEngagementOneHourSelected,
    engagementTwoHoursSelected,
    setEngagementTwoHoursSelected,
    engagementOneHourPrice,
    engagementOneHourPriceWithOnePhotographer,
    engagementTwoHoursPrice,
    engagementTwoHoursPriceWithOnePhotographer,
    // Wedding Albums
    uriChange,
    setUriChange,
    // 10x10
    weddingAlbum10Price,
    setWeddingAlbum10Price,
    weddingAlbum10PriceTotal,
    setWeddingAlbum10PriceTotal,
    weddingAlbum10Selected,
    setWeddingAlbum10Selected,
    weddingAlbum10Amount,
    setWeddingAlbum10Amount,
    weddingAlbum10AmountFree,
    setWeddingAlbum10AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    setWeddingAlbum10ProfessionalDesignAmount,
    weddingAlbum10ProfessionalDesignSelected,
    setWeddingAlbum10ProfessionalDesignSelected,
    weddingAlbum10Control,
    setWeddingAlbum10Control,
    weddingAlbum10Info,
    setWeddingAlbum10Info,
    weddingAlbum10AmountFreeInfo,
    setWeddingAlbum10AmountFreeInfo,
    weddingAlbum10ProfessionalDesignInfo,
    setWeddingAlbum10ProfessionalDesignInfo,
    weddingAlbum10PriceDefault,
    weddingAlbum10ShippingFee,
    weddingAlbum10ShippingFeeTotal,
    setWeddingAlbum10ShippingFeeTotal,
    weddingAlbum10ProfessionalDesignPrice,
    weddingAlbum10ProfessionalDesignPriceTotal,
    setWeddingAlbum10ProfessionalDesignPriceTotal,
    // 12x12
    weddingAlbum12Price,
    setWeddingAlbum12Price,
    weddingAlbum12PriceTotal,
    setWeddingAlbum12PriceTotal,
    weddingAlbum12Selected,
    setWeddingAlbum12Selected,
    weddingAlbum12Amount,
    setWeddingAlbum12Amount,
    weddingAlbum12AmountFree,
    setWeddingAlbum12AmountFree,
    weddingAlbum12ProfessionalDesignAmount,
    setWeddingAlbum12ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignSelected,
    setWeddingAlbum12ProfessionalDesignSelected,
    weddingAlbum12Control,
    setWeddingAlbum12Control,
    weddingAlbum12Info,
    setWeddingAlbum12Info,
    weddingAlbum12AmountFreeInfo,
    setWeddingAlbum12AmountFreeInfo,
    weddingAlbum12ProfessionalDesignInfo,
    setWeddingAlbum12ProfessionalDesignInfo,
    weddingAlbum12PriceDefault,
    weddingAlbum12ShippingFee,
    weddingAlbum12ShippingFeeTotal,
    setWeddingAlbum12ShippingFeeTotal,
    weddingAlbum12ProfessionalDesignPrice,
    weddingAlbum12ProfessionalDesignPriceTotal,
    setWeddingAlbum12ProfessionalDesignPriceTotal,
    // Photo Retouching
    photoRetouchingSelected,
    setPhotoRetouchingSelected,
    retouchedPhotos10Price,
    photoRetouching10Selected,
    setPhotoRetouching10Selected,
    retouchedPhotos20Price,
    photoRetouching20Selected,
    setPhotoRetouching20Selected,
    retouchedPhotos30Price,
    photoRetouching30Selected,
    setPhotoRetouching30Selected,
    photoRetouchingPackageInfo,
    setPhotoRetouchingPackageInfo,
    photoRetouchingPackagePrice,
    setPhotoRetouchingPackagePrice,
    // Expedited Photo Processing
    expeditedPhotoProcessingPrice,
    expeditedPhotoProcessingSelected,
    setExpeditedPhotoProcessingSelected,
    // Premium package (Photo)
    premiumPhotoPackageSelected,
    setPremiumPhotoPackageSelected,
    premiumPhotoPackagePrice,
    setPremiumPhotoPackagePrice,
    premiumPhotoPackagePriceDefault,
    // premiumPhotoPackagePriceDiscount,
    premiumPhotoPackageDescription,
    premiumPhotoPackagePopupTitle,
    setPremiumPhotoPackagePopupTitle,
    // premiumPhotoPackagePopupTitleDefault,
    // premiumPhotoPackagePopupTitleDiscount,
    premiumPhotoPackagePopupBody,
    // Videography
    // One Videographer
    oneVideographerSelected,
    setOneVideographerSelected,
    oneVideographerControl,
    setOneVideographerControl,
    oneVideographerHour,
    setOneVideographerHour,
    oneVideographerPriceDefault,
    oneVideographerPrice,
    setOneVideographerPrice,
    oneVideographerPriceForAbove8,
    oneVideographerPriceForBelow8,
    oneVideographerPriceWithOnePhotographer,
    // Second Videographer
    secondVideographerSelected,
    setSecondVideographerSelected,
    secondVideographerControl,
    setSecondVideographerControl,
    secondVideographerHour,
    setSecondVideographerHour,
    secondVideographerPriceDefault,
    secondVideographerPrice,
    setSecondVideographerPrice,
    secondVideographerPriceForAbove8,
    secondVideographerPriceForBelow8,
    // Video Editing Options
    // Cinematography
    cinematographySelected,
    setCinematographySelected,
    cinematographyPrice,
    setCinematographyPrice,
    cinematographyPriceWithOneVideographer,
    cinematographyPriceWithSecondVideographer,
    // Trailer Only
    trailerOnlySelected,
    setTrailerOnlySelected,
    trailerOnlyPrice,
    setTrailerOnlyPrice,
    trailerOnlyPriceWithOneVideographer,
    trailerOnlyPriceWithSecondVideographer,
    // Extended Trailer
    extendedTrailerSelected,
    setExtendedTrailerSelected,
    extendedTrailerPrice,
    setExtendedTrailerPrice,
    extendedTrailerPriceWithOneVideographer,
    extendedTrailerPriceWithSecondVideographer,
    // Traditional
    traditionalSelected,
    setTraditionalSelected,
    traditionalPrice,
    setTraditionalPrice,
    traditionalPriceWithOneVideographer,
    traditionalPriceWithSecondVideographer,
    // Premium package (Video)
    premiumVideoPackageSelected,
    setPremiumVideoPackageSelected,
    premiumVideoPackageDescription,
    setPremiumVideoPackageDescription,
    premiumVideoPackagePrice,
    setPremiumVideoPackagePrice,
    premiumVideoPackagePriceDefault,
    premiumVideoPackagePriceDiscount,
    premiumVideoPackageDescriptionDefault,
    premiumVideoPackagePopupTitle,
    setPremiumVideoPackagePopupTitle,
    premiumVideoPackagePopupTitleDefault,
    premiumVideoPackagePopupTitleDiscount,
    premiumVideoPackagePopupBody,
    // Social Media Package (Sneak Peek)
    // Photo
    socialMediaPackagePhotoSelected,
    setSocialMediaPackagePhotoSelected,
    socialMediaPackagePhotoPrice,
    socialMediaPackagePhotoReels,
    // Video
    socialMediaPackageVideoSelected,
    setSocialMediaPackageVideoSelected,
    socialMediaPackageVideoPrice,
    socialMediaPackageVideoReels,
    // Photo & Video
    socialMediaPackagePhotoAndVideoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
    socialMediaPackagePhotoAndVideoPrice,
    socialMediaPackagePhotoAndVideoReels,
    // Photo Booth
    photoBoothPackagePrice,
    setPhotoBoothPackagePrice,
    // Basic Package
    photoBoothBasicSelected,
    setPhotoBoothBasicSelected,
    photoBoothBasicPackagePriceDefault,
    photoBoothBasicPackagePrice,
    setPhotoBoothBasicPackagePrice,
    photoBoothBasicPackagePriceWithOnePhotographer,
    // All Inclusive Package
    photoBoothAllInclusiveSelected,
    setPhotoBoothAllInclusiveSelected,
    photoBoothAllInclusivePriceDefault,
    photoBoothAllInclusivePrice,
    setPhotoBoothAllInclusivePrice,
    photoBoothAllInclusivePriceWithOnePhotographer,
  };
};
